import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { ProfileAvatarWrapper, ProfileDisplayName, getProfileUsername, getProfileDisplayName, ProfileLocation, ProfileJoinDate, ProfileDescription, } from '@entities/profile';
import ProfileNickname from '@entities/profile/ui/ProfileNickname';
import { ReactComponent as CopyIcon } from '@icons/custom/add-copy-light.svg';
import Button from '@shared/ui/buttons/Button';
import ResetButton from '@shared/ui/buttons/ResetButton';
import AvatarImage from '@shared/ui/display/AvatarImage';
import VerificationIcon from '@shared/ui/icons/VerificationIcon';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import Dropdown from '@shared/ui/menus/Dropdown';
const OwnerDescriptionContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    padding: `${props.theme.spacing_sizes.l}px ${props.theme.spacing_sizes.l}px 
              0 ${props.theme.spacing_sizes.l}px`,
    boxSizing: 'border-box',
    marginBottom: props.theme.spacing_sizes.m,
}));
const OwnerInfoHeading = styled(ContainerRow)(props => ({
    justifyContent: 'space-between',
    marginBottom: props.theme.spacing_sizes.m,
}));
const OwnerMainInfo = styled(ContainerRow)(props => ({
    width: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const ResetButtonContainer = styled.div();
const OwnerName = styled(ContainerColumn)(props => ({
    width: 'auto',
    height: 'auto',
    justifyContent: 'center',
    gap: props.theme.spacing_sizes.xs * 0.5,
}));
const DisplayNameContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const JoinDateLocation = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.l,
    marginBottom: props.theme.spacing_sizes.s,
}));
const ProfileButtonsContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
    marginBottom: props.theme.spacing_sizes.s,
}));
const PortfolioOwnerDescription = ({ profile, handleClose, }) => {
    var _a;
    const { t } = useTranslation();
    const username = getProfileUsername(profile);
    const displayName = getProfileDisplayName(profile);
    const theme = useTheme();
    return (_jsxs(OwnerDescriptionContainer, { children: [_jsxs(OwnerInfoHeading, { children: [_jsxs(OwnerMainInfo, { children: [_jsx(ProfileAvatarWrapper, { size: '64px', children: _jsx(AvatarImage, { publicName: profile === null || profile === void 0 ? void 0 : profile.displayName, imageUrl: (_a = profile === null || profile === void 0 ? void 0 : profile.profilePicture) === null || _a === void 0 ? void 0 : _a.avatar, size: 'small' }) }), _jsxs(OwnerName, { children: [_jsxs(DisplayNameContainer, { children: [_jsx(ProfileDisplayName, { style: {
                                                    fontSize: 24,
                                                    lineHeight: '30px',
                                                }, children: displayName }), _jsx(VerificationIcon, { verified: Boolean(profile.isVerifiedTrader), size: 24 })] }), _jsx(ProfileNickname, { children: username })] })] }), _jsx(ResetButtonContainer, { children: _jsx(ResetButton, { onClick: handleClose, iconPadding: theme.spacing_sizes.s }) })] }), _jsxs(JoinDateLocation, { children: [_jsx(ProfileLocation, { location: profile.location }), _jsx(ProfileJoinDate, { creationDate: profile.createdAt })] }), _jsx(ProfileDescription, { profileId: profile.id, text: profile.description }), _jsxs(ProfileButtonsContainer, { children: [_jsx(Button, { variant: 'tinted', fullWidth: true, disabled: true, children: t('marketplace.all_portfolio_view.portfolio_info.trader_following') }), _jsx(Button, { fullWidth: true, variant: 'tinted', children: t('marketplace.all_portfolio_view.portfolio_info.view_profile') }), _jsx(Dropdown, { buttonVariant: 'tinted', options: [] })] }), _jsx(Button, { startIcon: CopyIcon, fullWidth: true, children: t('portfolio_overview.sections.button.start_copy', { ns: 'common' }) })] }));
};
export default PortfolioOwnerDescription;
